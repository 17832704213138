@import url("https://fonts.googleapis.com/css?family=Noto Sans");
@import url("https://fonts.googleapis.com/css?family=Arya");
@import url("https://fonts.googleapis.com/css?family=Source Sans Pro");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Noto Sans", "Arya", "Source Sans Pro", sans-serif,
    'Noto Sans Devanagari', 'Noto Sans Tamil', 
    'Noto Sans', 'Noto Sans Symbols 2';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

